<template>
    <div>
        <div>
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div class="input-group-prepend">
                        <span 
                            class="input-group-text brl" 
                            id="basic-addon1" 
                            style="
                                font-size: 2rem;
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px;
                            "
                        >$</span>
                    </div>
                    <currency-input
                        :value="usdAmount"
                        @change="usdAmount = $event"
                        class=""
                        style="
                            font-size: 2rem;
                            text-align: center;
                            border-top-right-radius: 15px; 
                            border-bottom-right-radius: 15px; outline: none;
                            border: 1px solid #ced4da;
                            background-color: #e9ecef;
                            color: #7BAA64;
                        "
                        :options="{
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            hideCurrencySymbolOnFocus: false,
                            hideGroupingSeparatorOnFocus: false,
                            hideNegligibleDecimalDigitsOnFocus: false,
                            useGrouping: true,
                            valueRange: { min: 0, max: 9999999999 },
                        }"
                    />
                </div>
                <div class="my-auto mx-4 h1"> ≆ </div>
                <div class="d-flex">
                    <div class="input-group-prepend">
                        <span 
                            class="input-group-text brl" 
                            id="basic-addon1" 
                            style="
                                font-size: 2rem;
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px;
                            "
                        >{{selectedCurrency.symbol}} </span>
                    </div>
                    <currency-input
                        :value="depositAmountCrypto"
                        disabled
                        class=""
                        style="
                            font-size: 2rem;
                            text-align: center;
                            border-top-right-radius: 15px; 
                            border-bottom-right-radius: 15px; outline: none;
                            border: 1px solid #ced4da;
                            background-color: #e9ecef;
                            color: #7BAA64;
                        "
                        :options="{
                            currency: selectedCurrency.short,
                            currencyDisplay: 'hidden',
                            hideCurrencySymbolOnFocus: false,
                            hideGroupingSeparatorOnFocus: false,
                            hideNegligibleDecimalDigitsOnFocus: false,
                            useGrouping: true,
                            precision: 8,
                            valueRange: { min: 0, max: 9999999999 },
                        }"
                    />
                </div>
            </div>

            <div class="text-center mt-3">
                All transactions are processed in USD. User is responsible for transaction fees.
            </div>
        </div>

        <div class="mt-3 d-flex">

                <div class="input-group-prepend dropdown">
                    <a
                        href="#"
                        class="input-group-text brl dropdown-toggle"
                        id="gameDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        style="
                            font-size: 1.2rem;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            border-top-left-radius: 15px;
                            border-bottom-left-radius: 15px;
                            background-color: #e3e3e3;
                            height: 40px;
                            cursor: pointer;
                            padding: 0 20px;
                        "
                    >
                        {{ selectedCurrency.short }}
                        <!-- <font-awesome-icon class="ml-2" icon="caret-down"  style="color: #495057;"/> -->
                    </a>
                    <div class="dropdown-menu" aria-labelledby="gameDropdown">
                        <a v-for="currency in currencies" :key="currency.id" class="dropdown-item" href="#" @click="selectedCurrencyId = currency.id">{{ currency.short }}</a>
                    </div>
                </div>
                <input 
                
                    :value="selectedCurrencyAddress"
                    disabled
                    @focus="$event.target.select()"
                    @click="$event.target.select()"
                    style="
                        font-size: 1rem;
                        text-align: center;
                        border-top-right-radius: 15px; 
                        border-bottom-right-radius: 15px; outline: none;
                        border: 1px solid #ced4da;
                        background-color: #fdfdfd;
                        color: black;
                        height: 40px;
                        border-left: 0;
                    "
                >

                <div
                    v-tooltip="'Copy to Clipboard'"
                    @click="copyToClipboard(selectedCurrencyAddress)"
                    class="mx-2 p-2"
                    style="
                        cursor: pointer;
                        border: 1px solid black;
                        border-radius: 15px;
                        height: 40px;
                        min-width: 40px;
                    ">
                    <icon-contentcopy class="icon-15x mx-auto my-auto"  />
                </div>
                <div
                    v-tooltip="'view QR'"
                    @click="showQR"
                    class="p-2"
                    style="
                        cursor: pointer;
                        border: 1px solid black;
                        border-radius: 15px;
                        height: 40px;
                        min-width: 42px;
                    ">
                    <icon-qrcodescan class="icon-15x mx-auto my-auto"  />
                </div>
        </div>


        <div class="text-center mt-3">
            Send any amount to the wallet address above. Conversion rate is determined when the payment is confirmed on the blockchain. Deposits sent on the Coinbase network will process instantly. Please allow up to one hour for transactions sent on the blockchain to process.
        </div>
        <!-- <div class="mt-2 text-center">
            <button @click="checkNow" :disabled="checking" class="btn btn-teal">
                <template v-if="!checking">Check now</template>
                <template v-if="checking">
                    Check now
                    <div id="loader"></div>
                </template>

            </button>
        </div> -->
<!-- 
        <div class="d-flex">
            <div>
                <img src="../../assets/coinbase.jpg" style="height: 100px"> 
            </div>
            <div class="h4 my-auto pr-5">
                Bonus of $10 in free Bitcoin when you buy or sell $100 on Coinbase
            </div>
        </div>

        <div class="mt-1 text-center">
            <a href="https://www.coinbase.com/" target="coinbase" class="btn btn-teal">
                SIGN UP
            </a>
        </div> -->
    </div>
</template>

<script>
import api from '../../api';
import Swal from 'sweetalert2';
const QRCode = require("qrcode");

export default {
    data() {
        return {
            currencies: [
                { id: "BTC", symbol: "₿", short: "BTC", property: "btcAddress" },
                { id: "ETH", symbol: "Ð", short: "ETH", property: "ethAddress" },
                { id: "USDT", symbol: "$", short: "USDT", property: "usdtAddress" },
            ],
            selectedCurrencyId: "BTC",
            depositAmountCrypto: 1,
            rates: {},
            checking: false,
        }
    },
    computed: {
        rate() {
            return this.rates[this.selectedCurrencyId + "-USD"] || 1;
        },
        selectedCurrency() {
            return this.currencies.find(x => x.id == this.selectedCurrencyId);
        },
        selectedCurrencyAddress() {
            if (!this.user.payment_info.coinbase) return ""

            return this.user.payment_info.coinbase[this.selectedCurrency.property];
        },
        user() {
            return this.$store.getters["auth/user"];
        },
        hasCoinbaseConnected() {
            return this.user.payment_info.coinbase;
        },
        usdAmount: {
            get: function() {
                return this.depositAmountCrypto * this.rate;
            },
            set: function(newValue) {
                this.depositAmountCrypto = newValue / this.rate;
            }
        }
    },
    async mounted() {
        if (!this.hasCoinbaseConnected) {
            this.connect();
        }
        console.log("getting rate")
        this.getRate()
    },
    methods: {
        async checkNow() {
            try {
                this.checking = true;
                const { data: newTransactions } = await api.post(`/Users/me/Payments/Coinbase/check`);
                if (newTransactions.length > 0) {
                    Swal.fire({
                        title: "Success",
                        text: `We found ${newTransactions.length} transactions, your account will be credited shortly.`,
                        icon: 'success'
                    });
                }
                this.checking = false;
            } catch (e) {

            } finally {
                this.checking = false;
            }
        },
        copyToClipboard(text){
            let self = this;
            console.log(`Copying ${text}`)
            this.$copyText(text).then(function (e) {
                //console.log(e)
                self.$toasted.show('Copied!',{
                duration: 1000,
                position: 'bottom-left'
                });
            }, function (e) {
                console.error('Failed to copy to clipboard.');
            })
        },
        async showQR() {
            Swal.showLoading();
            var imageUrl = await new Promise((res, rej) => QRCode.toDataURL(this.selectedCurrencyAddress, (err, img) => err ? rej(err) : res(img)));
            
            let result = await Swal.fire({
                imageUrl,
                imageWidth: 200,
                imageHeight: 200,
                imageAlt: "Custom image",
                html: `<div class="d-flex justify-content-center mt-2"><div class="copyme">${this.selectedCurrencyAddress}</div></div>`,
                showCloseButton: true,
                showConfirmButton: false,
                cancelButtonText: `Cancel`,
                heightAuto: false,
            });
        },
        async connect() {
            await Swal.showLoading();

            try {
                // Lets make a deposit address
                let response = await api.handleAdditionalDetails(1, "Coinbase", {
                    command: "connect"
                });

                // We have a payment ID now 
                await this.$store.dispatch('auth/refreshUser', this.user);
                Swal.close()
            } catch (e) {
                console.log(e.stack)
                await Swal.fire("Oops", "Unable to connect with coinbase at this time. Please try again later", "error");
                this.$emit('failureLoading');
                return;
            }

        },
        async getRate() {
            try {
                console.log("Getting rate")
                // Lets make a deposit address
                let response = await api.handleAdditionalDetails(1, "Coinbase", {
                    command: "rates"
                });
                console.log("Got rate")

                this.rates = response.data["Coinbase"].methods;
                console.log(this.rate);

            } catch (e) {
                console.log("Unable to get rate");
                console.log(e.stack);
            }
        },
    },
}

//fix modal force focus
$.fn.modal.Constructor.prototype.enforceFocus = function () {
  var that = this;
  $(document).on('focusin.modal', function (e) {
      return true;
     if ($(e.target).hasClass('select2-input')) {
        return true;
     }

     if (that.$element[0] !== e.target && !that.$element.has(e.target).length) {
        that.$element.focus();
     }
  });
};

</script>

<style>
.material-design-icon.icon-15x {
  height: 21px;
  width: 21px;
  /* padding: */
}
.material-design-icon.icon-15x > .material-design-icon__svg {
  height: 1.5em;
  width: 1.5em;
}
</style>